<template>

  <b-card
    no-body
    class="mb-0"
  >
    <titulo-card-com-voltar
      voltar="perfis"
      titulo="Permissões do Perfil"
    />
    <b-card>

      <b-row>
        <b-col cols="12">
          <b-card-text>
            <b>Nome:</b> {{ itemData.name }}
          </b-card-text>
          <!--          <b-input-group>-->
          <!--            <b-form-input-->
          <!--              v-model="itemPesquisa"-->
          <!--              placeholder="Pesquisar"-->
          <!--            />-->
          <!--            <b-input-group-append>-->
          <!--              <b-button variant="outline-primary">-->
          <!--                <feather-icon-->
          <!--                  icon="SearchIcon"-->
          <!--                  size="14"-->
          <!--                />-->
          <!--              </b-button>-->
          <!--            </b-input-group-append>-->
          <!--          </b-input-group>-->
        </b-col>
        <b-col
          v-for="perm in listaPermissoesComputed"
          :key="perm"
          cols="3"
        >
          <b-form-checkbox-group>
            <b-form-checkbox
              v-model="permissoesSelecionadas"
              class="mx-1 mt-1"
              :value="perm"
            >
              {{ perm }}

            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          v-if="listaPermissoes.length"
          variant="success"
          @click="salvarPermissoes"
        >
          Salvar
        </b-button>
      </div>
    </b-card>

  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import {
  computed, nextTick, onUnmounted, ref, watch,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'

import useItemList from '@/views/apps/perfis/list/useItemList'
import clienteStoreModule from '../perfisStoreModule'

export default {
  components: {
    TituloCardComVoltar,
    BFormCheckbox,
    BInputGroupAppend,
    BFormInput,
    BFormCheckboxGroup,
    BCard,
    BCol,
    BCardText,
    BRow,
    BInputGroup,
    BButton,
  },
  setup() {
    const itemData = ref({})
    const listaPermissoes = ref([])
    const permissoesSelecionadas = ref([])
    const itemPesquisa = ref('')

    const permissoesSelecionadasComputed = computed(() => permissoesSelecionadas.value)
    const listaPermissoesComputed = computed(() => listaPermissoes.value)

    const pesquisar = () => {
      listaPermissoes.value = store.state['app-perfis'].listaPermissoes

      listaPermissoes.value = listaPermissoes.value.filter(perm => perm.includes(itemPesquisa.value))
    }

    const PERFIS_APP_STORE_MODULE_NAME = 'app-perfis'

    // Register module
    if (!store.hasModule(PERFIS_APP_STORE_MODULE_NAME)) store.registerModule(PERFIS_APP_STORE_MODULE_NAME, clienteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PERFIS_APP_STORE_MODULE_NAME)) store.unregisterModule(PERFIS_APP_STORE_MODULE_NAME)
    })

    async function fetchPermissoesEItemData() {
      try {
        const response = await store.dispatch('app-perfis/listaPermissoes', { perPage: 1000 })
        listaPermissoes.value = response.data.map(perm => perm.name)

        try {
          const respo = await store.dispatch('app-perfis/show', { name: router.currentRoute.params.name })
          itemData.value = respo.data
          permissoesSelecionadas.value = itemData.value.permissions.map(perm => perm.name)
        } catch (error) {
          if (error.response && error.response.status === 404) {
            itemData.value = undefined
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          itemData.value = undefined
        }
      }
    }

    fetchPermissoesEItemData()
    const {
      update,
    } = useItemList()

    const salvarPermissoes = () => {
      itemData.value.permissions = permissoesSelecionadas.value

      update(itemData.value)
    }
    watch(itemPesquisa, (newValue, oldValue) => {
      if (newValue.length > 2) {
        pesquisar()
      }

      if (newValue.length === 0) {
        listaPermissoes.value = store.state['app-perfis'].listaPermissoes
        nextTick(() => {
          const novasPermissoes = itemData.value.permissions
            .map(perm => perm.name)
            .filter(permName => !permissoesSelecionadas.value.includes(permName))

          permissoesSelecionadas.value = permissoesSelecionadas.value.concat(novasPermissoes)
        })
      }
    })

    return {
      itemData,
      permissoesSelecionadas,
      listaPermissoes,
      pesquisar,
      itemPesquisa,
      permissoesSelecionadasComputed,
      listaPermissoesComputed,
      salvarPermissoes,
    }
  },
}
</script>
